<template>
  <page-template>
    <template #nav>
      <basic-nav>
        <template #nav-center>
          <h1 class="text-3xl">Verifone Admin</h1>
        </template>
      </basic-nav>
    </template>
    <template #body>
      <div class="flex justify-center">
        <basic-card title="Verifone Device Status" class="w-11/12 md:w-1/2">
          <template #body>
            <div class="w-full text-left items-center">
              <div v-if="loading" class="flex">
                <font-awesome-icon class="mr-2" icon="spinner" spin color="orange"/>
                <h2>Getting device info...</h2>
              </div>
              <div v-else class="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-center md:space-x-6">
                <KioskDetailCard style="width: 100%">
                  <KioskDetailCardItem label="Kiosk Name" :value="kioskDetails.kioskName"/>
                  <KioskDetailCardItem label="Terminal Id" :value="verifoneConfiguration.poiid"/>
                  <KioskDetailCardItem :border="false" label="Sale Id" :value="verifoneConfiguration.sale_id"/>
                </KioskDetailCard>
              </div>
            </div>
          </template>
        </basic-card>
      </div>
    </template>
  </page-template>
</template>

<script>
import PageTemplate from "@/components/Helpers/Pages/PageTemplate";
import BasicNav from "@/components/Helpers/Navs/BasicNav";
import BasicCard from "@/components/Helpers/Cards/BasicCard";
import {mapActions, mapGetters} from "vuex";
import KioskDetailCard from "../../components/Cards/KioskDetailCard.vue";
import KioskDetailCardItem from "../../components/Cards/KioskDetailCardItem.vue";
import ToastMixin from "../../mixin/ToastMixin";

export default {
  name: "VerifoneStatus",
  components: { BasicCard, BasicNav, PageTemplate, KioskDetailCard, KioskDetailCardItem },
  mixins: [ToastMixin],
  data() {
    return {
      loading: true,
      unregisteringDevice: false,
      testingCounter: false,
    };
  },
  computed: {
    ...mapGetters("CreditCardMachine", ["verifoneConfiguration"]),
    ...mapGetters("GeneralSettings", ["kioskDetails"]),
  },
  async mounted() {
    try {
      await this.getDeviceInfo();
    } catch (e) {
      this.errorToast("Failed to fetch device status")
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("CreditCardMachine", ["getDeviceInfo", "testCounter", "resetDevices"]),
    showConnectionErrorToast() {
      this.errorToast("Failed to connect to verifone device.", 6000);
    },
  }
}
</script>

<style scoped>

</style>
